// src/pages/AdminRentalPolicyPage.js
import React, { useEffect, useState } from 'react';
import api from '../api';

const AdminRentalPolicyPage = () => {
    const [rentalPolicy, setRentalPolicy] = useState({
        discountClosedDaysAndHolidays: false,
        allowOnlinePayment: false,
        allowInStorePayment: false,
        useNonrefundableFee: false,
        nonrefundableFeeAmount: 0,
        nonrefundableFeeWindowHours: 24
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch the existing rental policy
    useEffect(() => {
        const fetchRentalPolicy = async () => {
            try {
                const response = await api.get('/store/rental-policy');
                setRentalPolicy(response.data);
            } catch (err) {
                console.error('Failed to fetch rental policy:', err);
                setError('Could not load rental policy.');
            } finally {
                setLoading(false);
            }
        };
        fetchRentalPolicy();
    }, []);

    // Handle form changes
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setRentalPolicy(prev => ({
            ...prev,
            [name]: checked,
        }));
    };

    const handleNumberChange = (e) => {
        const { name, value } = e.target;
        setRentalPolicy(prev => ({
            ...prev,
            [name]: parseFloat(value),
        }));
    };

    const handleIntegerChange = (e) => {
        const { name, value } = e.target;
        setRentalPolicy(prev => ({
            ...prev,
            [name]: parseInt(value, 10),
        }));
    };

    const handleSave = async () => {
        try {
            const response = await api.put('/store/rental-policy', rentalPolicy);
            setRentalPolicy(response.data);
            alert('Rental policy updated successfully!');
        } catch (error) {
            console.error('Failed to update rental policy:', error);
            alert('Failed to update rental policy.');
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p style={{ color: 'red' }}>{error}</p>;

    return (
        <div style={{ maxWidth: '600px', margin: '0 auto' }}>
            <h1>Store Rental Policies</h1>

            <div style={{ marginBottom: '1em' }}>
                <label>
                    <input
                        type="checkbox"
                        name="discountClosedDaysAndHolidays"
                        checked={rentalPolicy.discountClosedDaysAndHolidays}
                        onChange={handleCheckboxChange}
                    />
                    <span style={{ marginLeft: '0.5em' }}>
            Discount Closed Days and Holidays
          </span>
                </label>
            </div>

            <div style={{ marginBottom: '1em' }}>
                <label>
                    <input
                        type="checkbox"
                        name="allowOnlinePayment"
                        checked={rentalPolicy.allowOnlinePayment}
                        onChange={handleCheckboxChange}
                    />
                    <span style={{ marginLeft: '0.5em' }}>Allow Online Payment</span>
                </label>
            </div>

            <div style={{ marginBottom: '1em' }}>
                <label>
                    <input
                        type="checkbox"
                        name="allowInStorePayment"
                        checked={rentalPolicy.allowInStorePayment}
                        onChange={handleCheckboxChange}
                    />
                    <span style={{ marginLeft: '0.5em' }}>Allow In-Store Payment</span>
                </label>
            </div>

            <div style={{ marginBottom: '1em' }}>
                <label>
                    <input
                        type="checkbox"
                        name="useNonrefundableFee"
                        checked={rentalPolicy.useNonrefundableFee}
                        onChange={handleCheckboxChange}
                    />
                    <span style={{ marginLeft: '0.5em' }}>Use Nonrefundable Fee</span>
                </label>
            </div>

            <div style={{ marginBottom: '1em' }}>
                <label>Nonrefundable Fee Amount:</label>
                <br />
                <input
                    type="number"
                    step="0.01"
                    name="nonrefundableFeeAmount"
                    value={rentalPolicy.nonrefundableFeeAmount}
                    onChange={handleNumberChange}
                    style={{ width: '100px' }}
                    disabled={!rentalPolicy.useNonrefundableFee}
                />
            </div>

            <div style={{ marginBottom: '1em' }}>
                <label>Nonrefundable Fee Window (hours):</label>
                <br />
                <input
                    type="number"
                    name="nonrefundableFeeWindowHours"
                    value={rentalPolicy.nonrefundableFeeWindowHours}
                    onChange={handleIntegerChange}
                    style={{ width: '100px' }}
                    disabled={!rentalPolicy.useNonrefundableFee}
                />
            </div>

            <button onClick={handleSave} style={{ marginTop: '1em', padding: '0.5em 1em' }}>
                Save Policy
            </button>
        </div>
    );
};

export default AdminRentalPolicyPage;
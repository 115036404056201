import React, { useEffect, useState, useContext } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../api';
import { CartContext } from '../context/CartContext';
import AuthContext from '../context/AuthContext';
import './CheckoutPage.css';

/**
 * The same discount logic from CartPage:
 *  e.g. $120 base - discount(20%) => $96 total for 3 days => per-day is $32
 */
function calcDiscountedPrice(basePrice, discount) {
    if (!discount) return basePrice;
    const discountAmount = parseFloat(discount.discountAmount) || 0;
    const discountPercent = parseFloat(discount.discountPercent) || 0;
    let price = basePrice;
    if (discountPercent > 0) {
        price = price * (1 - discountPercent);
    } else if (discountAmount > 0) {
        price -= discountAmount;
        if (price < 0) price = 0;
    }
    return price;
}

const CheckoutPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { cart, loadCart } = useContext(CartContext);
    const { user } = useContext(AuthContext);

    // We assume CartPage passed these final totals:
    const {
        notes = '',
        subtotalBeforeDiscount = 0,
        totalDiscount = 0,
        subtotalAfterDiscount = 0,
        taxAmount = 0,
        grandTotal = 0,
        // The discount object used for item-level logic
        activeDiscount = null,
        userTaxRate = 0.07
    } = location.state || {};

    // Payment policy
    const [policy, setPolicy] = useState(null);
    const [loadingPolicy, setLoadingPolicy] = useState(true);
    const [error, setError] = useState(null);

    // Payment method
    const [selectedMethod, setSelectedMethod] = useState('');
    const [payInStoreForRemainder, setPayInStoreForRemainder] = useState(false);

    // Customer info
    const [customerName, setCustomerName] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');

    // Payment error display
    const [paymentError, setPaymentError] = useState(null);

    // PayPal client ID from .env
    const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;

    // On mount, if cart is empty, optionally redirect
    useEffect(() => {
        if (cart.length === 0) {
            // navigate('/cart');
        }
        if (user) {
            setCustomerName(`${user.firstName} ${user.lastName}`);
            setCustomerEmail(user.email);
        }
    }, [cart, user]);

    // Fetch rental policy
    useEffect(() => {
        const fetchPolicy = async () => {
            try {
                const resp = await api.get('/store/rental-policy');
                setPolicy(resp.data);
            } catch (err) {
                console.error('Failed to load rental policy:', err);
                setError('Failed to load rental policy.');
            } finally {
                setLoadingPolicy(false);
            }
        };
        fetchPolicy();
    }, []);

    if (loadingPolicy) return <p>Loading policy...</p>;
    if (error) return <p style={{ color: 'red' }}>{error}</p>;
    if (!policy) return <p>No policy found.</p>;

    /**
     * STEP 1: Compute total discounted cost for ALL items so we can
     *         distribute partial deposit (NR fee) proportionally.
     */
    function getTotalDiscountedCost() {
        let total = 0.0;
        for (const ci of cart) {
            const base = parseFloat(ci.unitPrice);
            const discounted = calcDiscountedPrice(base, activeDiscount);
            // The item’s total for the entire rental, e.g. $96 for 3 days
            total += discounted;
        }
        return total;
    }

    /**
     * buildLightspeedItems():
     *   For each cart line:
     *    - compute the item’s discounted total
     *    - if paying partial deposit, subtract the deposit share => leftover
     *    - if paying in full => leftover=0
     *    - if paying in store => leftover= item’s entire discounted total
     *    - then leftover / quantity => per-day unit price for Lightspeed
     */
    const buildLightspeedItems = () => {
        const totalDiscountedAll = getTotalDiscountedCost();

        // Decide deposit
        let depositNow = 0.0;
        if (selectedMethod === 'PAYPAL') {
            if (policy.useNonrefundableFee && policy.allowInStorePayment && payInStoreForRemainder) {
                // partial deposit = nonrefundable fee
                depositNow = policy.nonrefundableFeeAmount;
            } else {
                // paying full cost
                depositNow = totalDiscountedAll; // means leftover=0
            }
        }
        else if (selectedMethod === 'IN_STORE') {
            // $0 now, leftover= entire cost
            depositNow = 0.0;
        }

        return cart.map(ci => {
            // discount logic for entire item cost:
            const basePrice = parseFloat(ci.unitPrice);
            const discounted = calcDiscountedPrice(basePrice, activeDiscount); // total cost for this item

            // figure out the proportion of deposit for this item
            let itemDepositShare = 0.0;
            if (depositNow > 0 && totalDiscountedAll > 0) {
                itemDepositShare = (discounted / totalDiscountedAll) * depositNow;
            }
            // leftover can't be negative
            let leftover = discounted - itemDepositShare;
            if (leftover < 0) leftover = 0.0;

            // quantity = # days or # units
            const qty = parseInt(ci.unitQuantity, 10);
            if (qty <= 0) {
                console.warn("Invalid quantity, defaulting to 1");
            }

            // final per-day leftover cost
            let perDay = 0.0;
            if (qty > 0) {
                perDay = leftover / qty;
            }

            return {
                itemID: ci.itemID,
                // pass the leftover per-day cost as unitPrice
                unitPrice: perDay.toFixed(2),
                employeeID: ci.employeeID,
                shopID: ci.shopID,
                customerID: ci.customerId,
                unitQuantity: ci.unitQuantity,
                startDate: ci.startDate,
                endDate: ci.endDate
            };
        });
    };

    /**
     * handleInStoreCheckout:
     *   user pays $0 now, leftover = entire discounted cost => perDay
     */
    const handleInStoreCheckout = async () => {
        setPaymentError(null);

        if (!selectedMethod || selectedMethod !== 'IN_STORE') {
            setPaymentError("Please select 'In-Store' method.");
            return;
        }

        try {
            const lightspeedItems = buildLightspeedItems();

            const payload = {
                paymentMethod: 'IN_STORE',
                currency: 'USD',
                payInStoreForRemainder: false,
                customerName,
                customerEmail,
                notes,
                subtotalBeforeDiscount,
                totalDiscount,
                subtotalAfterDiscount,
                taxAmount,
                grandTotal,
                userTaxRate,
                lightspeedItems
            };

            const resp = await api.post('/checkout', payload);
            if (!resp.data.success) {
                setPaymentError(
                    "We couldn't complete the in-store checkout. " +
                    "Please try again or contact us. (Details: " + resp.data.message + ")"
                );
                return;
            }
            // success
            loadCart([]);
            navigate('/confirmation');
        } catch (err) {
            console.error("In-store checkout error:", err);
            setPaymentError(
                "Your in-store checkout could not be completed. Please try again or contact support."
            );
        }
    };

    /**
     * PayPal: partial deposit => leftover = discounted - item’s share of deposit
     *  or pay in full => leftover=0 => Lightspeed lines get 0.00
     */
    const renderPayPalButtons = () => {
        // If partial deposit, only pay the nrFee, else entire grandTotal
        let amountToCharge = grandTotal;
        if (policy.useNonrefundableFee && policy.allowInStorePayment && payInStoreForRemainder) {
            amountToCharge = policy.nonrefundableFeeAmount;
        }

        const lightspeedItems = buildLightspeedItems();

        return (
            <PayPalScriptProvider options={{ 'client-id': clientId }}>
                <PayPalButtons
                    style={{ layout: 'vertical' }}
                    createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [{
                                amount: { value: amountToCharge.toFixed(2) }
                            }]
                        });
                    }}
                    onApprove={async (data, actions) => {
                        setPaymentError(null);
                        try {
                            const details = await actions.order.capture();
                            console.log('PayPal capture details:', details);

                            const captureId = details.purchase_units[0].payments.captures[0].id;

                            const payload = {
                                paymentMethod: 'PAYPAL',
                                currency: 'USD',
                                payInStoreForRemainder,
                                customerName,
                                customerEmail,
                                notes,
                                paypalCaptureId: captureId,
                                subtotalBeforeDiscount,
                                totalDiscount,
                                subtotalAfterDiscount,
                                taxAmount,
                                grandTotal,
                                userTaxRate,
                                lightspeedItems
                            };

                            const resp = await api.post('/checkout', payload);
                            if (!resp.data.success) {
                                setPaymentError(
                                    "We couldn't validate your payment after PayPal authorization. " +
                                    "Please try again or use a different payment method. (Details: " + resp.data.message + ")"
                                );
                                return;
                            }

                            loadCart([]);
                            navigate('/confirmation');
                        } catch (finalizeErr) {
                            console.error('PayPal finalize error:', finalizeErr);
                            setPaymentError(
                                "Your payment was authorized but we couldn't finalize. " +
                                "Please contact support or try again."
                            );
                        }
                    }}
                    onCancel={() => {
                        console.warn('PayPal payment canceled.');
                        setPaymentError(
                            "Payment was canceled. You may select a different method or try again."
                        );
                    }}
                    onError={(err) => {
                        console.error('PayPal payment error:', err);
                        let message =
                            "We couldn't validate your payment. Please verify your card " +
                            "or PayPal account and try again.";

                        if (typeof err === 'object' && err.message) {
                            if (err.message.includes("DECLINED")) {
                                message =
                                    "Your payment was declined by your bank or card issuer. " +
                                    "Please try another payment method.";
                            } else if (
                                err.message.includes("VALIDATION_ERROR") ||
                                err.message.includes("OAS_VALIDATION_ERROR")
                            ) {
                                message =
                                    "There was an issue validating your credit card details. " +
                                    "Please double-check and try again.";
                            }
                        }
                        setPaymentError(message);
                    }}
                />
            </PayPalScriptProvider>
        );
    };

    return (
        <div className="checkout-container">
            <h1>Checkout</h1>

            {paymentError && (
                <div className="payment-error-box">
                    {paymentError}
                </div>
            )}

            {/* Show final totals for user reference */}
            <div className="checkout-totals">
                <p>
                    <span>Subtotal (Before Discount):</span>
                    <span>${subtotalBeforeDiscount.toFixed(2)}</span>
                </p>
                {totalDiscount > 0 && (
                    <p>
                        <span>Discount:</span>
                        <span>-${totalDiscount.toFixed(2)}</span>
                    </p>
                )}
                <p>
                    <span>Subtotal (After Discount):</span>
                    <span>${subtotalAfterDiscount.toFixed(2)}</span>
                </p>
                <p>
                    <span>Tax:</span>
                    <span>${taxAmount.toFixed(2)}</span>
                </p>
                <p>
                    <strong>Grand Total:</strong>
                    <strong>${grandTotal.toFixed(2)}</strong>
                </p>
            </div>

            <div className="payment-method-section">
                <h2>Select Payment Method</h2>
                {policy.allowOnlinePayment && (
                    <>
                        <label>
                            <input
                                type="radio"
                                name="paymentMethod"
                                value="PAYPAL"
                                checked={selectedMethod === 'PAYPAL'}
                                onChange={() => setSelectedMethod('PAYPAL')}
                            />
                            Pay with Credit, Debit, or PayPal
                        </label>
                        {policy.useNonrefundableFee && policy.allowInStorePayment && selectedMethod === 'PAYPAL' && (
                            <div className="payment-method-suboption">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={payInStoreForRemainder}
                                        onChange={(e) => setPayInStoreForRemainder(e.target.checked)}
                                    />
                                    Pay only the nonrefundable fee now, pay remainder in store
                                </label>
                                {payInStoreForRemainder && (
                                    <p style={{ color: 'green' }}>
                                        Nonrefundable Fee = ${policy.nonrefundableFeeAmount.toFixed(2)}
                                        <br />
                                        You'll pay the rest in-store at pickup.
                                    </p>
                                )}
                            </div>
                        )}
                    </>
                )}

                {policy.allowInStorePayment && (
                    <div style={{ marginTop: '1em' }}>
                        <label>
                            <input
                                type="radio"
                                name="paymentMethod"
                                value="IN_STORE"
                                checked={selectedMethod === 'IN_STORE'}
                                onChange={() => {
                                    setSelectedMethod('IN_STORE');
                                    setPayInStoreForRemainder(false);
                                }}
                            />
                            Pay In-Store
                        </label>
                        {policy.useNonrefundableFee && selectedMethod === 'IN_STORE' && (
                            <p style={{ color: 'green', marginLeft: '2em' }}>
                                Note: A nonrefundable fee of $
                                {policy.nonrefundableFeeAmount.toFixed(2)} may apply if you cancel
                                within {policy.nonrefundableFeeWindowHours} hours of your start date.
                            </p>
                        )}
                    </div>
                )}

                {!policy.allowOnlinePayment && !policy.allowInStorePayment && (
                    <p style={{ color: 'red' }}>No payment methods currently available.</p>
                )}
            </div>

            <div className="checkout-userinfo">
                <label>Full Name:</label><br />
                <input
                    type="text"
                    value={customerName}
                    readOnly
                    style={{ width: '100%', marginBottom: '1em' }}
                />

                <label>Email:</label><br />
                <input
                    type="email"
                    value={customerEmail}
                    readOnly
                    style={{ width: '100%' }}
                />
            </div>

            {selectedMethod === 'PAYPAL' && (
                <div className="paypal-button-container">
                    {renderPayPalButtons()}
                </div>
            )}

            {selectedMethod === 'IN_STORE' && (
                <button className="instore-button" onClick={handleInStoreCheckout}>
                    Proceed with In-Store
                </button>
            )}
        </div>
    );
};

export default CheckoutPage;
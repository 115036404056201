// src/pages/AdminTaxPage.js
import React, { useEffect, useState } from 'react';
import api from '../api';
import './AdminTaxPage.css';

const AdminTaxPage = () => {
    const [taxCategories, setTaxCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchTaxes();
    }, []);

    const fetchTaxes = async () => {
        try {
            const response = await api.get('/lightspeed/taxes');
            setTaxCategories(response.data);
        } catch (err) {
            console.error('Failed to load tax categories:', err);
            setError('Failed to load tax categories');
        } finally {
            setLoading(false);
        }
    };

    const handleToggleActive = async (taxCategoryId, currentActive) => {
        const newActive = !currentActive;
        try {
            await api.put(`/lightspeed/taxes/${taxCategoryId}/active?active=${newActive}`);
            fetchTaxes();
        } catch (err) {
            console.error('Failed to toggle tax active state:', err);
            alert('Failed to toggle tax active state');
        }
    };

    const handleSetDefault = async (taxCategoryId) => {
        try {
            // We use the /user/ route that sets default and does the user bulk update
            await api.put(`/user/${taxCategoryId}/default`);
            fetchTaxes();
        } catch (err) {
            console.error('Failed to set default tax category:', err);
            alert('Failed to set default tax category');
        }
    };

    if (loading) return <p className="loading-text">Loading tax categories...</p>;
    if (error) return <p className="error-text">{error}</p>;

    return (
        <div className="admin-tax-container">
            <h1 className="admin-tax-title">Lightspeed Tax Categories</h1>
            {taxCategories.length === 0 ? (
                <p>No tax categories found.</p>
            ) : (
                <table className="admin-tax-table">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Tax Name</th>
                        <th>Tax1 Rate</th>
                        <th>Tax2 Name</th>
                        <th>Tax2 Rate</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {taxCategories.map((tax) => {
                        const isActive = tax.active;
                        const isDefault = tax.default;

                        // Build a short status string
                        // e.g. "Active (Default)" or "Inactive" etc.
                        let statusLabel = isActive ? 'Active' : 'Inactive';
                        if (isDefault) statusLabel += ' (Default)';

                        return (
                            <tr key={tax.taxCategoryID}>
                                <td>{tax.taxCategoryID}</td>
                                <td>{tax.tax1Name}</td>
                                <td>{(tax.tax1Rate * 100).toFixed(2)}%</td>
                                <td>{tax.tax2Name || '-'}</td>
                                <td>{tax.tax2Rate > 0 ? (tax.tax2Rate * 100).toFixed(2) + '%' : '-'}</td>
                                <td>{statusLabel}</td>
                                <td>
                                    {/*
                                            Show one or two small buttons:
                                            1) Toggle Active
                                            2) If not default, show 'Set Default'
                                        */}
                                    <button
                                        onClick={() => handleToggleActive(tax.taxCategoryID, isActive)}
                                        className="toggle-active-btn"
                                    >
                                        {isActive ? 'Deactivate' : 'Activate'}
                                    </button>
                                    {' '}
                                    {!isDefault && (
                                        <button
                                            onClick={() => handleSetDefault(tax.taxCategoryID)}
                                            className="toggle-default-btn"
                                        >
                                            Set Default
                                        </button>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default AdminTaxPage;
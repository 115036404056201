// src/pages/CartPage.js
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api';
import { CartContext } from '../context/CartContext';
import './CartPage.css';

function rangesOverlap(startA, endA, startB, endB) {
    return startA <= endB && startB <= endA;
}

function hasOverlappingBookingsForSameItem(cart) {
    const itemsById = {};
    cart.forEach((ci) => {
        if (!itemsById[ci.itemID]) {
            itemsById[ci.itemID] = [];
        }
        itemsById[ci.itemID].push(ci);
    });

    for (const itemId in itemsById) {
        const bookings = itemsById[itemId];
        if (bookings.length > 1) {
            for (let i = 0; i < bookings.length; i++) {
                const aStart = new Date(bookings[i].startDate);
                const aEnd = new Date(bookings[i].endDate);
                for (let j = i + 1; j < bookings.length; j++) {
                    const bStart = new Date(bookings[j].startDate);
                    const bEnd = new Date(bookings[j].endDate);
                    if (rangesOverlap(aStart, aEnd, bStart, bEnd)) {
                        return true;
                    }
                }
            }
        }
    }
    return false;
}

const CartPage = () => {
    const navigate = useNavigate();
    const { cart, loadCart, removeFromCart } = useContext(CartContext);

    const [userProfile, setUserProfile] = useState(null);
    const [userLoading, setUserLoading] = useState(true);
    const [cartLoading, setCartLoading] = useState(true);
    const [error, setError] = useState(null);

    const [assignedDiscount, setAssignedDiscount] = useState(null);
    const [typedDiscountCode, setTypedDiscountCode] = useState('');
    const [overrideDiscount, setOverrideDiscount] = useState(null);

    const [taxCategories, setTaxCategories] = useState([]);
    const [userTaxRate, setUserTaxRate] = useState(0.07);

    const [userNotes, setUserNotes] = useState('');

    // 1) Fetch user profile
    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const resp = await api.get('/profile');
                setUserProfile(resp.data);
                if (resp.data.assignedDiscountId) {
                    try {
                        const discResp = await api.get('/lightspeed/discounts/search', {
                            params: { discountId: resp.data.assignedDiscountId },
                        });
                        setAssignedDiscount(discResp.data);
                    } catch (discountErr) {
                        console.error("Failed to fetch assigned discount:", discountErr);
                    }
                }
            } catch (err) {
                console.error('Failed to load user profile:', err);
                setError('Failed to load user profile. Please refresh the page.');
            } finally {
                setUserLoading(false);
            }
        };
        fetchUserProfile();
    }, []);

    // 2) Fetch cart items
    useEffect(() => {
        const fetchCartItems = async () => {
            try {
                const resp = await api.get('/cart');
                loadCart(resp.data);
            } catch (err) {
                console.error('Failed to load cart items:', err);
                setError('Failed to load cart items.');
            } finally {
                setCartLoading(false);
            }
        };
        fetchCartItems();
    }, [loadCart]);

    // 3) Fetch tax categories
    useEffect(() => {
        const fetchTaxCats = async () => {
            try {
                const resp = await api.get('/lightspeed/taxes');
                setTaxCategories(resp.data);
            } catch (err) {
                console.error('Failed to fetch tax categories', err);
            }
        };
        fetchTaxCats();
    }, []);

    // 4) Derive user tax rate
    useEffect(() => {
        if (userProfile && userProfile.assignedTaxCategoryId && taxCategories.length > 0) {
            const found = taxCategories.find(tc => tc.taxCategoryID === userProfile.assignedTaxCategoryId);
            setUserTaxRate(found ? found.tax1Rate : 0.07);
        }
    }, [userProfile, taxCategories]);

    const overallLoading = userLoading || cartLoading;
    if (overallLoading) {
        return <p>Loading cart...</p>;
    }
    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }
    if (!userProfile) {
        return <p style={{ color: 'red' }}>No user profile loaded.</p>;
    }

    const appliedDiscount = overrideDiscount || assignedDiscount;

    const handleCheckDiscount = async () => {
        if (!typedDiscountCode) {
            setOverrideDiscount(null);
            return;
        }
        try {
            const resp = await api.get('/lightspeed/discounts/search', {
                params: { name: typedDiscountCode },
            });
            setOverrideDiscount(resp.data);
        } catch (err) {
            console.error('Failed to fetch override discount', err);
            setOverrideDiscount(null);
        }
    };

    // 5) Calculate cart totals
    const calcDiscountedPrice = (basePrice) => {
        if (!appliedDiscount) return basePrice;
        const discountAmount = parseFloat(appliedDiscount.discountAmount) || 0;
        const discountPercent = parseFloat(appliedDiscount.discountPercent) || 0;
        let price = basePrice;
        if (discountPercent > 0) {
            price = price * (1 - discountPercent);
        } else if (discountAmount > 0) {
            price -= discountAmount;
            if (price < 0) price = 0;
        }
        return price;
    };

    let subtotalBeforeDiscount = 0;
    let subtotalAfterDiscount = 0;
    cart.forEach(ci => {
        const basePrice = parseFloat(ci.unitPrice);
        subtotalBeforeDiscount += basePrice;
        subtotalAfterDiscount += calcDiscountedPrice(basePrice);
    });
    const totalDiscount = subtotalBeforeDiscount - subtotalAfterDiscount;
    const taxAmount = subtotalAfterDiscount * userTaxRate;
    const grandTotal = subtotalAfterDiscount + taxAmount;

    const handleRemove = async (id) => {
        try {
            await api.delete(`/cart/${id}`);
            removeFromCart(id);
        } catch (err) {
            console.error('Failed to remove item:', err);
        }
    };

    const handleGoToCheckout = () => {
        if (hasOverlappingBookingsForSameItem(cart)) {
            alert("You have overlapping rental dates for the same item. Please remove or adjust those items first.");
            return;
        }
        navigate('/checkout', {
            state: {
                notes: userNotes,
                subtotalBeforeDiscount: subtotalBeforeDiscount,
                subtotalAfterDiscount: subtotalAfterDiscount,
                totalDiscount: totalDiscount,
                taxAmount: taxAmount,
                grandTotal: grandTotal,
            }
        });
    };

    return (
        <div className="cart-container">
            <h1>Your Cart</h1>

            {/* The discount code area */}
            <div className="discount-section">
                <label htmlFor="discountInput">Discount Code (Optional): </label>
                <input
                    id="discountInput"
                    type="text"
                    value={typedDiscountCode}
                    onChange={(e) => setTypedDiscountCode(e.target.value)}
                    placeholder="Type discount name..."
                />
                <button onClick={handleCheckDiscount}>Check Discount</button>
            </div>

            {/* If there's an assigned discount, show a styled message */}
            {appliedDiscount && !overrideDiscount && (
                <div className="discount-applied">
                    <p>
                        <strong>Applied Discount:</strong> {appliedDiscount.name}
                        {appliedDiscount.discountPercent > 0
                            ? ` (${(appliedDiscount.discountPercent * 100).toFixed(0)}%)`
                            : appliedDiscount.discountAmount > 0
                                ? ` ($${appliedDiscount.discountAmount} off)`
                                : ''
                        }
                    </p>
                    <button
                        onClick={() => {
                            setTypedDiscountCode('');
                            setOverrideDiscount(null);
                            setAssignedDiscount(null);
                        }}
                    >
                        Remove Discount
                    </button>
                </div>
            )}

            {cart.length === 0 ? (
                <p className="empty-cart">Your cart is empty.</p>
            ) : (
                <>
                    <div className="cart-header-row">
                        <div className="cart-header-image">Image</div>
                        <div className="cart-header-description">Item</div>
                        <div className="cart-header-dates">Start Date</div>
                        <div className="cart-header-dates">End Date</div>
                        <div className="cart-header-unitprice">Base Price</div>
                        <div className="cart-header-quantity">Qty</div>
                        <div className="cart-header-total">Discounted</div>
                        <div className="cart-header-action">Action</div>
                    </div>

                    <div className="cart-items">
                        {cart.map((ci) => {
                            const basePrice = parseFloat(ci.unitPrice);
                            const discountedPrice = calcDiscountedPrice(basePrice);
                            const quantity = parseInt(ci.unitQuantity, 10);
                            const perDay = (discountedPrice / quantity).toFixed(2);
                            const savings = basePrice - discountedPrice;

                            return (
                                <div className="cart-item-row" key={ci.id}>
                                    <img
                                        src={ci.imageUrl || '/placeholder-image.jpg'}
                                        alt={ci.itemDescription || 'Item'}
                                        className="cart-item-image"
                                    />
                                    <div className="cart-item-description">
                                        {ci.itemDescription || 'No Description Available'}
                                    </div>
                                    <div className="cart-item-dates">{ci.startDate}</div>
                                    <div className="cart-item-dates">{ci.endDate}</div>
                                    <div className="cart-item-unit-price">
                                        ${basePrice.toFixed(2)}
                                    </div>
                                    <div className="cart-item-quantity">
                                        {ci.unitQuantity}
                                    </div>
                                    <div className="cart-item-total">
                                        <div><strong>${discountedPrice.toFixed(2)}</strong></div>
                                        {appliedDiscount && savings > 0 && (
                                            <small style={{color: 'green'}}>
                                                You save ${savings.toFixed(2)}
                                            </small>
                                        )}
                                        <div className="per-day">
                                            <small>({perDay} / day)</small>
                                        </div>
                                    </div>
                                    <button
                                        className="cart-item-remove"
                                        onClick={() => handleRemove(ci.id)}
                                    >
                                        Remove
                                    </button>
                                </div>
                            );
                        })}
                    </div>

                    {/*
                       NEW: Put Summary and Notes side by side
                       using a flex container cart-lower-section.
                    */}
                    <div className="cart-lower-section">
                        {/* The Notes Box */}
                        <div className="notes-box">
                            <h4>Notes (Optional)</h4>
                            <textarea
                                value={userNotes}
                                onChange={(e) => setUserNotes(e.target.value)}
                                rows={6}
                                style={{ width: '100%' }}
                                placeholder="Any special requests or notes?"
                            />
                        </div>

                        {/* The Summary */}
                        <div className="cart-summary">
                            <div className="cart-summary-row">
                                <span>Subtotal (before discount):</span>
                                <span>${subtotalBeforeDiscount.toFixed(2)}</span>
                            </div>
                            {totalDiscount > 0 && (
                                <div className="cart-summary-row">
                                    <span>Total Discount:</span>
                                    <span>-${totalDiscount.toFixed(2)}</span>
                                </div>
                            )}
                            <div className="cart-summary-row">
                                <span>Subtotal (after discount):</span>
                                <span>${subtotalAfterDiscount.toFixed(2)}</span>
                            </div>
                            <div className="cart-summary-row">
                                <span>Tax ({(userTaxRate * 100).toFixed(2)}%):</span>
                                <span>${taxAmount.toFixed(2)}</span>
                            </div>
                            <div className="cart-summary-row cart-summary-total">
                                <span>Grand Total:</span>
                                <span>${grandTotal.toFixed(2)}</span>
                            </div>
                        </div>
                    </div>

                    <button
                        className="confirm-booking-button"
                        onClick={handleGoToCheckout}
                    >
                        Checkout
                    </button>
                </>
            )}
        </div>
    );
};

export default CartPage;
// src/context/CartContext.js
import React, { createContext, useState, useCallback } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cart, setCart] = useState([]);

    // useCallback ensures the function reference stays the same
    // unless 'setCart' changes (which won't):
    const loadCart = useCallback((newCart) => {
        setCart(newCart);
    }, []);

    const addToCart = (item) => {
        setCart(prev => [...prev, item]);
    };

    const removeFromCart = (itemId) => {
        setCart(prev => prev.filter(item => item.id !== itemId));
    };

    return (
        <CartContext.Provider value={{ cart, loadCart, addToCart, removeFromCart }}>
            {children}
        </CartContext.Provider>
    );
};
import React, { useState, useEffect, useRef } from 'react';
import api from '../api';
import './StoreInfoPage.css';

const StoreInfoPage = () => {
    // Store hours
    const [storeHours, setStoreHours] = useState([]);
    const [loadingHours, setLoadingHours] = useState(true);
    const [errorHours, setErrorHours] = useState(null);
    const [isSubmittingHours, setIsSubmittingHours] = useState(false);

    // Store info fields
    const [storeLocation, setStoreLocation] = useState('');
    const [storePhone, setStorePhone] = useState('');
    const [storeInstructions, setStoreInstructions] = useState('');

    const [loadingInfo, setLoadingInfo] = useState(true);
    const [errorInfo, setErrorInfo] = useState(null);
    const [isSubmittingInfo, setIsSubmittingInfo] = useState(false);

    // Logo states
    const fileInputRef = useRef(null); // for clearing the file input
    const [logoFile, setLogoFile] = useState(null);
    const [isUploadingLogo, setIsUploadingLogo] = useState(false);
    const [logoUploadError, setLogoUploadError] = useState(null);

    // We'll store the public URL to display the image preview
    const [logoUrl, setLogoUrl] = useState('');

    // S3
    const BUCKET_NAME = 'rentranger-uploads';
    const AWS_REGION = 'us-east-2';

    // ----------------------------------------------------------------------
    // 1) Fetch store hours
    useEffect(() => {
        const fetchStoreHours = async () => {
            try {
                const response = await api.get('/store/hours');
                setStoreHours(response.data);
            } catch (err) {
                setErrorHours('Failed to fetch store hours.');
                console.error(err);
            } finally {
                setLoadingHours(false);
            }
        };
        fetchStoreHours();
    }, []);

    // ----------------------------------------------------------------------
    // 2) Fetch store info (location, phone, instructions, logo)
    const fetchStoreInfo = async () => {
        try {
            const resp = await api.get('/store/info');
            // If any fields are empty, default to ''
            const data = resp.data || {};
            setStoreLocation(data.storeLocation || '');
            setStorePhone(data.storePhone || '');
            setStoreInstructions(data.storeInstructions || '');

            // If storeLogoFilename is not empty, build a public URL
            if (data.storeLogoFilename) {
                const key = data.storeLogoFilename;
                // Use region in the URL
                const url = `https://${BUCKET_NAME}.s3.${AWS_REGION}.amazonaws.com/${key}`;
                setLogoUrl(url);
            } else {
                setLogoUrl('');
            }
        } catch (err) {
            setErrorInfo('Failed to fetch store info.');
            console.error(err);
        } finally {
            setLoadingInfo(false);
        }
    };

    useEffect(() => {
        fetchStoreInfo();
    }, []); // load store info on mount

    // ----------------------------------------------------------------------
    // Handle store hours changes
    const handleHoursInputChange = (index, field, value) => {
        const updated = [...storeHours];
        updated[index][field] = value;
        setStoreHours(updated);
    };

    // Submit store hours
    const handleSubmitHours = async () => {
        setIsSubmittingHours(true);
        try {
            await api.put('/store/hours', storeHours);
            alert('Store hours updated successfully!');
        } catch (err) {
            alert('Failed to update store hours.');
            console.error(err);
        } finally {
            setIsSubmittingHours(false);
        }
    };

    // ----------------------------------------------------------------------
    // Submit store info (location, phone, instructions)
    const handleSubmitInfo = async () => {
        setIsSubmittingInfo(true);
        try {
            await api.put('/store/info', {
                storeLocation,
                storePhone,
                storeInstructions,
            });
            alert('Store info updated successfully!');
            // Re-fetch to ensure we see any changes (like if the backend merges fields)
            fetchStoreInfo();
        } catch (err) {
            alert('Failed to update store info.');
            console.error(err);
        } finally {
            setIsSubmittingInfo(false);
        }
    };

    // ----------------------------------------------------------------------
    // Logo upload
    const handleFileChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            setLogoFile(e.target.files[0]);
        }
    };

    const handleUploadLogo = async () => {
        if (!logoFile) {
            alert('Please select a file first.');
            return;
        }
        setIsUploadingLogo(true);
        setLogoUploadError(null);

        try {
            const formData = new FormData();
            formData.append('logo', logoFile);

            await api.post('/store/logo', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            alert('Logo uploaded successfully!');

            // 1) Clear the file input on success
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            setLogoFile(null);

            // 2) Re-fetch store info to get the new storeLogoFilename -> update preview
            await fetchStoreInfo();
        } catch (err) {
            setLogoUploadError('Failed to upload logo.');
            console.error(err);
        } finally {
            setIsUploadingLogo(false);
        }
    };

    // ----------------------------------------------------------------------
    // If still loading
    if (loadingHours || loadingInfo) {
        return <p>Loading store info...</p>;
    }
    // If any errors
    if (errorHours) {
        return <p style={{ color: 'red' }}>{errorHours}</p>;
    }
    if (errorInfo) {
        return <p style={{ color: 'red' }}>{errorInfo}</p>;
    }

    // ----------------------------------------------------------------------
    return (
        <div className="store-info-container">
            <h1>Manage Store Information</h1>

            {/* Store Hours */}
            <section className="store-hours-section">
                <h2>Store Hours</h2>
                <table className="store-hours-table">
                    <thead>
                    <tr>
                        <th>Day</th>
                        <th>Open</th>
                        <th>Opening Time</th>
                        <th>Closing Time</th>
                    </tr>
                    </thead>
                    <tbody>
                    {storeHours.map((sh, index) => (
                        <tr key={sh.dayOfWeek}>
                            <td>{sh.dayOfWeek}</td>
                            <td>
                                <input
                                    type="checkbox"
                                    checked={sh.open}
                                    onChange={(e) =>
                                        handleHoursInputChange(index, 'open', e.target.checked)
                                    }
                                />
                            </td>
                            <td>
                                <input
                                    type="time"
                                    value={sh.openingTime || ''}
                                    onChange={(e) =>
                                        handleHoursInputChange(index, 'openingTime', e.target.value)
                                    }
                                    disabled={!sh.open}
                                />
                            </td>
                            <td>
                                <input
                                    type="time"
                                    value={sh.closingTime || ''}
                                    onChange={(e) =>
                                        handleHoursInputChange(index, 'closingTime', e.target.value)
                                    }
                                    disabled={!sh.open}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <button onClick={handleSubmitHours} disabled={isSubmittingHours}>
                    {isSubmittingHours ? 'Updating...' : 'Update Store Hours'}
                </button>
            </section>

            {/* Store Info */}
            <section className="store-info-section">
                <h2>Store Info</h2>
                <div className="info-field">
                    <label>Store Location:</label>
                    <textarea
                        value={storeLocation}
                        onChange={(e) => setStoreLocation(e.target.value)}
                        rows={2}
                        style={{ width: '100%', marginBottom: '10px' }}
                    />
                </div>
                <div className="info-field">
                    <label>Store Phone:</label>
                    <input
                        type="text"
                        value={storePhone}
                        onChange={(e) => setStorePhone(e.target.value)}
                        style={{ width: '100%', marginBottom: '10px' }}
                    />
                </div>
                <div className="info-field">
                    <label>Store Instructions:</label>
                    <textarea
                        value={storeInstructions}
                        onChange={(e) => setStoreInstructions(e.target.value)}
                        rows={4}
                        style={{ width: '100%', marginBottom: '10px' }}
                    />
                </div>
                <button onClick={handleSubmitInfo} disabled={isSubmittingInfo}>
                    {isSubmittingInfo ? 'Updating...' : 'Update Store Info'}
                </button>
            </section>

            {/* Store Logo */}
            <section className="store-logo-section">
                <h2>Store Logo</h2>
                <p>Upload a PNG, JPG, or JPEG for the store logo:</p>
                <div className="store-logo-controls">
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept=".png,.jpg,.jpeg"
                        onChange={handleFileChange}
                    />
                    <button onClick={handleUploadLogo} disabled={isUploadingLogo}>
                        {isUploadingLogo ? 'Uploading...' : 'Upload Logo'}
                    </button>

                    {/* If we have a logoUrl, show an image preview */}
                    {logoUrl && (
                        <img
                            src={logoUrl}
                            alt="Store Logo"
                            className="store-logo-preview"
                        />
                    )}
                </div>
                {logoUploadError && <p style={{ color: 'red' }}>{logoUploadError}</p>}
            </section>
        </div>
    );
};

export default StoreInfoPage;
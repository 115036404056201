// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import Profile from './pages/Profile';
import AccessoryPage from './pages/AccessoryPage';
import CameraPage from './pages/CameraPage';
import LensPage from './pages/LensPage';
import LightingPage from './pages/LightingPage';
import ProjectorPage from './pages/ProjectorPage';
import TripodPage from './pages/TripodPage';
import UsedItemPage from './pages/UsedItemPage';
import Footer from './components/common/Footer';
import PrivateRoute from './components/common/PrivateRoute';
import { AuthProvider } from './context/AuthContext';
import Header from './components/common/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container} from 'react-bootstrap';
import AllItemsPage from './pages/AllItemsPage';
import ItemDetailPage from './pages/ItemDetailPage';
import AdminItemsPage from './pages/AdminItemsPage';
import AdminPricingPage from './pages/AdminPricingPage';
import 'react-datepicker/dist/react-datepicker.css';
import Unauthorized from './pages/Unauthorized';
import AdminHomePage from './pages/AdminHomePage';
import StoreInfoPage from './pages/StoreInfoPage';
import HolidaysPage from './pages/HolidaysPage';
import AdminLayout from './components/AdminLayout';
import AdminItemDetailsPage from './pages/AdminItemDetailsPage';
import AdminRentalPolicyPage from "./pages/AdminRentalPolicyPage";
import AdminBookingsCalendar from './pages/AdminBookingsCalendar';
import BookingDetailsModal from "./components/BookingDetailsModal";
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import CartPage from "./pages/CartPage";
import ConfirmationPage from "./pages/ConfirmationPage";
import AdminUsersPage from './pages/AdminUsersPage';
import VerifyEmailPage from './pages/VerifyEmailPage';
import AdminDiscountsPage from "./pages/AdminDiscountsPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsPage from "./pages/TermsPage";
import AdminUserDetailsPage from "./pages/AdminUserDetailsPage";
import HomePage from './pages/HomePage';
import VideoProductionPage from "./pages/VideoProductionPage";
import AdminTaxPage from "./pages/AdminTaxPage";
import { CartProvider } from './context/CartContext';
import "@fortawesome/fontawesome-free/css/all.min.css";
import CheckoutPage from "./pages/CheckoutPage";


const App = () => {
    return (
        <AuthProvider>
            <CartProvider>
                <Router>
                    <Header />
                    <Container>
                        <Routes>
                            {/* Public Routes */}
                            <Route path="/" element={<Home />} />
                            <Route path="/register" element={<Register />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/all-items" element={<AllItemsPage />} />
                            <Route path="/items/:itemId" element={<ItemDetailPage />} />
                            <Route path="/unauthorized" element={<Unauthorized />} />
                            <Route path="/forgot-password" element={<ForgotPassword />} />
                            <Route path="/reset-password" element={<ResetPassword />} />
                            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                            <Route path="/terms" element={<TermsPage />} />

                            {/* Verification Routes*/}
                            <Route path="/profile/verify-email" element={<VerifyEmailPage />} />

                            {/* Private Routes */}
                            <Route
                                path="/home"
                                element={
                                    <PrivateRoute>
                                        <HomePage />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="/profile"
                                element={
                                    <PrivateRoute>
                                        <Profile />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/cart"
                                element={
                                    <PrivateRoute>
                                        <CartPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/confirmation"
                                element={
                                    <PrivateRoute>
                                        <ConfirmationPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/accessories"
                                element={
                                    <PrivateRoute>
                                        <AccessoryPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/cameras"
                                element={
                                    <PrivateRoute>
                                        <CameraPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/lenses"
                                element={
                                    <PrivateRoute>
                                        <LensPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/lightings"
                                element={
                                    <PrivateRoute>
                                        <LightingPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/projectors"
                                element={
                                    <PrivateRoute>
                                        <ProjectorPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/tripods"
                                element={
                                    <PrivateRoute>
                                        <TripodPage />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path="/useditems"
                                element={
                                    <PrivateRoute>
                                        <UsedItemPage />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="/video-production"
                                element={
                                    <PrivateRoute>
                                        <VideoProductionPage />
                                    </PrivateRoute>
                                }
                            />

                            <Route
                                path="/checkout"
                                element={
                                    <PrivateRoute>
                                        <CheckoutPage />
                                    </PrivateRoute>
                                }
                            />

                            {/* Admin Routes */}
                                <Route
                                path="/admin/*"
                                element={
                                <PrivateRoute requiredRole="ROLE_ADMIN">
                                <AdminLayout />
                                </PrivateRoute>
                            }
                        >
                            <Route index element={<AdminHomePage />} />
                            <Route path="store-info" element={<StoreInfoPage />} />
                            <Route path="holidays" element={<HolidaysPage />} />
                            <Route path="items" element={<AdminItemsPage />} />
                            <Route path="items/:itemId/pricing" element={<AdminPricingPage />} />
                            <Route path="items/:itemId/details" element={<AdminItemDetailsPage />} />
                            <Route path="rental-policy" element={<AdminRentalPolicyPage />} />
                            <Route path="bookings-calendar" element={<AdminBookingsCalendar />} />
                            <Route path="modal-overlay" element={<BookingDetailsModal />} />
                            <Route path="manage-users" element={<AdminUsersPage />} />
                            <Route path="discounts" element={<AdminDiscountsPage />} />
                            <Route path="tax-category" element={<AdminTaxPage/>} />
                            <Route path="users" element={<AdminUsersPage />} />
                            <Route path="users/:userId" element={<AdminUserDetailsPage />} />
                        </Route>

                        {/* Fallback Route */}
                        <Route path="*" element={<Unauthorized />} />
                    </Routes>
                </Container>
                <Footer className="bg-dark text-white text-center py-3">
                    © 2024 Rent Ranger
                </Footer>
            </Router>
        </CartProvider>
</AuthProvider>
);
};

export default App;

// src/pages/AdminUserDetailsPage.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import api from '../api';
import './AdminUserDetailsPage.css';

const AdminUserDetailsPage = () => {
    const { userId } = useParams();
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    // Address form states
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [stateVal, setStateVal] = useState('');
    const [zip, setZip] = useState('');
    const [updateMsg, setUpdateMsg] = useState('');

    // For displaying the tax category's name
    const [taxCategories, setTaxCategories] = useState([]);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                // Load user data
                const userResp = await api.get(`/users/${userId}`);
                setUser(userResp.data);

                // Address fields
                setAddress1(userResp.data.address1 || '');
                setAddress2(userResp.data.address2 || '');
                setCity(userResp.data.city || '');
                setStateVal(userResp.data.state || '');
                setZip(userResp.data.zip || '');
            } catch (err) {
                console.error('Failed to fetch user details:', err);
                setError('Failed to load user details');
            } finally {
                setLoading(false);
            }
        };

        // Also fetch all tax categories so we can map ID => Name
        const fetchTaxCategories = async () => {
            try {
                const resp = await api.get('/lightspeed/taxes');
                setTaxCategories(resp.data);
            } catch (err) {
                console.error('Failed to load tax categories:', err);
            }
        };

        fetchUserDetails();
        fetchTaxCategories();
    }, [userId]);

    if (loading) return <p>Loading user details...</p>;
    if (error) return <p>{error}</p>;
    if (!user) return <p>User not found</p>;

    // Find the name of the user's assigned tax category
    let assignedTaxName = 'None';
    if (user.assignedTaxCategoryId) {
        const found = taxCategories.find(
            (tc) => tc.taxCategoryID === user.assignedTaxCategoryId
        );
        assignedTaxName = found ? found.tax1Name : `ID#${user.assignedTaxCategoryId}`;
    }

    // Handle address update
    const handleSubmitAddress = async (e) => {
        e.preventDefault();
        setUpdateMsg('');

        try {
            const response = await api.put(`/users/${userId}/address`, {
                address1,
                address2,
                city,
                state: stateVal,
                zip
            });
            setUpdateMsg(response.data);

            // Re-fetch user
            const refetch = await api.get(`/users/${userId}`);
            setUser(refetch.data);
        } catch (err) {
            console.error('Failed to update address:', err);
            if (err.response && err.response.data) {
                setUpdateMsg(err.response.data);
            } else {
                setUpdateMsg('Failed to update address');
            }
        }
    };

    return (
        <div className="admin-user-details-container">
            <h2>User Details (Admin View)</h2>
            <div className="user-info-section">
                <p><strong>Customer ID:</strong> {user.customerId}</p>
                <p><strong>Company:</strong> {user.company}</p>
                <p><strong>Name:</strong> {user.firstName} {user.lastName}</p>
                <p><strong>Email:</strong> {user.email}</p>
                <p><strong>Username:</strong> {user.username}</p>
                <p><strong>Mobile:</strong> {user.phone}</p>
                <p><strong>Work Phone:</strong> {user.workPhone}</p>

                {/* Display the tax category NAME (fallback to "None" or ID# if not found) */}
                <p>
                    <strong>Assigned Tax Category:</strong> {assignedTaxName}
                </p>
            </div>

            <div className="address-section">
                <h3>Update Address</h3>
                <Form onSubmit={handleSubmitAddress} className="address-form">
                    <Form.Group className="mb-2">
                        <Form.Label>Address 1</Form.Label>
                        <Form.Control
                            type="text"
                            value={address1}
                            onChange={(e) => setAddress1(e.target.value)}
                            required
                        />
                    </Form.Group>
                    {/* address2, city, state, zip */}
                    <Form.Group className="mb-2">
                        <Form.Label>Address 2</Form.Label>
                        <Form.Control
                            type="text"
                            value={address2}
                            onChange={(e) => setAddress2(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                            type="text"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>State</Form.Label>
                        <Form.Control
                            type="text"
                            value={stateVal}
                            onChange={(e) => setStateVal(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label>Zip</Form.Label>
                        <Form.Control
                            type="text"
                            value={zip}
                            onChange={(e) => setZip(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Update Address
                    </Button>
                </Form>
                {updateMsg && <p className="update-message">{updateMsg}</p>}
            </div>

            <Button variant="secondary" onClick={() => navigate('/admin/manage-users')}>
                Back to Manage Users
            </Button>
        </div>
    );
};

export default AdminUserDetailsPage;
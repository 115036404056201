// src/pages/ConfirmationPage.js
import React, { useEffect, useState } from 'react';
import api from '../api';
import './ConfirmationPage.css';

const BUCKET_NAME = 'rentranger-uploads';
const AWS_REGION = 'us-east-2';

const ConfirmationPage = () => {
    const [storeInfo, setStoreInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [storeLogoUrl, setStoreLogoUrl] = useState('');

    useEffect(() => {
        const fetchStoreInfo = async () => {
            try {
                const response = await api.get('/store/info');
                const data = response.data || {};
                setStoreInfo(data);

                // If there's a storeLogoFilename, build a public URL
                if (data.storeLogoFilename) {
                    const key = data.storeLogoFilename;
                    const url = `https://${BUCKET_NAME}.s3.${AWS_REGION}.amazonaws.com/${key}`;
                    setStoreLogoUrl(url);
                }
            } catch (error) {
                console.error('Failed to fetch store info:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchStoreInfo();
    }, []);

    if (loading) {
        return <p>Loading confirmation...</p>;
    }
    if (!storeInfo) {
        return <p>Could not load store information.</p>;
    }

    const storeLocation = storeInfo.storeLocation || '';
    const storePhone = storeInfo.storePhone || '';
    const storeInstructions = storeInfo.storeInstructions || '';

    return (
        <div className="confirmation-container">
            {/* A flex row that shows h1 on left, logo on right */}
            <div className="heading-and-logo">
                <h1>Booking Confirmed!</h1>
                {storeLogoUrl && (
                    <img
                        src={storeLogoUrl}
                        alt="Store Logo"
                        className="confirmation-logo"
                    />
                )}
            </div>

            <p>Your rental booking has been confirmed. We look forward to seeing you!</p>

            <h2>Store Location</h2>
            <p>{storeLocation}</p>

            <h2>Store Phone</h2>
            <p>{storePhone}</p>

            <h2>Pickup Instructions</h2>
            <p>{storeInstructions}</p>

            <h3>Your Next Steps</h3>
            <ul>
                <li>Check your email for a copy of your booking details.</li>
                <li>Bring a valid ID, and form of payment if you have any amount due remaining.</li>
            </ul>
        </div>
    );
};

export default ConfirmationPage;
//AuthContext.js
import React, { createContext, useState, useEffect, useCallback } from 'react';
import { authenticateUser, refreshToken } from '../services/authService';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext({
    user: null,
    loading: true,
    login: () => {},
    logout: () => {},
});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const handleTokenRefresh = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) return;

            console.log('Token before refresh:', token);

            const response = await refreshToken(token);
            console.log('Response from refreshToken:', response);
            const { newToken } = response;

            if (!newToken) {
                console.error('No newToken found in response');
                localStorage.removeItem('token');
                setUser(null);
                return;
            }

            localStorage.setItem('token', newToken);

            const refreshedUser = parseJwt(newToken);
            setUser(refreshedUser);

            console.log('Token successfully refreshed');
        } catch (error) {
            console.error('Failed to refresh token', error);
            localStorage.removeItem('token');
            setUser(null);
        }
    }, []);

    useEffect(() => {
        const initializeAuth = async () => {
            try {
                const token = localStorage.getItem('token');
                console.log('Retrieved token from localStorage:', token);
                if (token) {
                    try {
                        const user = parseJwt(token);
                        if (user && user.exp) {
                            const tokenExpiration = user.exp * 1000;
                            console.log('Token expires at:', new Date(tokenExpiration));

                            if (tokenExpiration > Date.now()) {
                                setUser(user);

                                if (tokenExpiration - Date.now() < 600000) {
                                    await handleTokenRefresh();
                                }
                            } else {
                                console.warn('Token has expired');
                                localStorage.removeItem('token');
                                setUser(null);
                            }
                        } else {
                            console.error('Invalid token payload:', user);
                            localStorage.removeItem('token');
                            setUser(null);
                        }
                    } catch (error) {
                        console.error('Invalid token', error);
                        localStorage.removeItem('token');
                        setUser(null);
                    }
                }
            } finally {
                setLoading(false);
            }
        };
        initializeAuth();
    }, [handleTokenRefresh]);

    const login = async (credentials) => {
        try {
            const response = await authenticateUser(credentials);
            console.log('Response from authenticateUser:', response);
            const { token } = response;

            if (!token) {
                console.error('No token found in response');
                // Handle error
                return;
            }

            localStorage.setItem('token', token);
            const user = parseJwt(token);
            setUser(user);
        } catch (error) {
            console.error('Login failed', error);
            throw error;
            // Handle login error (e.g., show a message to the user)
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        setUser(null);
    };

    const parseJwt = (token) => {
        try {
            const decoded = jwtDecode(token);
            console.log("Decoded token payload:", decoded);
            const roles = decoded.roles || [];
            return { ...decoded, roles };
        } catch (e) {
            console.error('Error decoding token:', e);
            return null;
        }
    };

    return (
        <AuthContext.Provider value={{ user, loading, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;